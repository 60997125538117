import React, { useRef, useState, useEffect } from "react";
import slider from "../assets/tech.svg";
import ad from "../assets/ad.svg";
import bd from "../assets/bd.svg";
import content from "../assets/content.svg";
import mr from "../assets/mr.svg";
import seo from "../assets/seo.svg";
import smd from "../assets/smd.svg";
import video from "../assets/video.svg";
import wd from "../assets/wd.svg";
import { Link } from "react-router-dom";

const Card = ({ title, description, icon }) => {
  return (
    <div className="bg-gradient-to-br from-[#1a5fe4] to-[#020639] text-white rounded-3xl p-6 shadow-lg flex flex-col w-[95%] sm:w-[calc(50%-1rem)] lg:w-1/3 h-auto shrink-0 gap-6">
      <div className="flex justify-between items-start gap-4">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-medium leading-tight">
          {title}
        </h2>
        <img src={icon} alt={title} className="w-16 h-16 sm:w-20 sm:h-20" />
      </div>
      <p className="text-sm sm:text-base lg:text-lg leading-relaxed font-kanit font-light">
        {description}
      </p>
    </div>
  );
};

const Cards = () => {
  const sliderRef = useRef(null);
  const animationFrameRef = useRef(null);
  const [scrollSpeed, setScrollSpeed] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);


  const cardData = [
    {
      title: "Advertising Campaigns",
      description:
        "Ready to connect with your audience wherever they shop, scroll, stream, or read? This is achievable now with Cypera's advertising campaigns. Together let us show up in the spaces your audience loves most and drive traffic, boost sales, and build loyalty.",
      icon: ad,
    },
    {
      title: "Content Creation",
      description:
        "Looking to create content that delivers true results? With Cypera, create content that captivates, converts, and dominates. From blog posts, articles, and social media posts to videos, everything you need to achieve unparalleled visibility and impact.",
      icon: content,
    },
    {
      title: "Market Research",
      description:
        "Curious about what drives your audience? Enter into the current market trends and buyer behaviors with Cypera's expert team, craft smarter strategies for hitting the right audience, and watch your ROI soar.",
      icon: mr,
    },
    {
      title: "Brand Development",
      description:
        "Is your brand making the impact it deserves? With Cypera, go beyond competition and take the lead. Transform insights into action, overcome brand challenges, and amplify your market presence like never before.",
      icon: bd,
    },
    {
      title: "Video and Motion Graphics",
      description:
        "Want to turn heads and captivate hearts? Team up with Cypera's creative minds to produce stunning videos and motion graphics that bring your story to life, highlight your products, and keep your audience engaged.",
      icon: video,
    },
    {
      title: "Social Media Design",
      description:
        "Tired of being just another post in the feed? With Cypera’s eye-catching designs, from bold logos to captivating visuals, ensure your brand grabs attention, boosts visibility, and makes a memorable impression.",
      icon: smd,
    },
    {
      title: "SEO Services",
      description:
        "Ready to take your website to the top? Cypera is here to fine-tune every element from keywords, search engine alignment, and technical enhancements, making your website more visible, more powerful, and fully optimized for any device.",
      icon: seo,
    },
    {
      title: "Website Design",
      description:
        "What if your website could be your brand's best storyteller? With Cypera's responsive, intuitive UI/UX designs, ensure an engaging experience across devices while letting your brand’s story shine.",
      icon: wd,
    },
  ];

  const smoothScroll = () => {
    if (sliderRef.current && scrollSpeed !== 0) {
      sliderRef.current.scrollLeft += scrollSpeed / 60;
      animationFrameRef.current = requestAnimationFrame(smoothScroll);
    }
  };

  useEffect(() => {
    if (isHovered && scrollSpeed !== 0) {
      animationFrameRef.current = requestAnimationFrame(smoothScroll);
    } else {
      cancelAnimationFrame(animationFrameRef.current);
    }
    return () => cancelAnimationFrame(animationFrameRef.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, scrollSpeed]);

  const handleMouseMove = e => {
    if (sliderRef.current) {
      const { left, width } = sliderRef.current.getBoundingClientRect();
      const x = e.clientX - left;

      const center = width / 2;
      const distanceFromCenter = x - center;

      const maxSpeed = 600;
      const newScrollSpeed = (distanceFromCenter / center) * maxSpeed;

      setScrollSpeed(newScrollSpeed);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setScrollSpeed(0);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);



  // const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });


  // const handleMouseMove2 = (e) => {
  //   setCursorPosition({ x: e.clientX, y: e.clientY });
  // };

  return (
    <div>
      {/* <div
          className="fixed pointer-events-none transform -translate-x-1/2 -translate-y-1/2"
          style={{
            left: `${cursorPosition.x}px`,
            top: `${cursorPosition.y}px`,
          
          }}
        >
          <div className="bg-white/25 backdrop-blur-sm  text-white rounded-full p-4 shadow-md">
           <p>Slider</p>
            {/* Replace with desired icon 
          </div>
        </div>*/}
      <div
        ref={sectionRef}
        className="text-white flex flex-col items-center justify-center px-4 mb-20">
        <h2
          className={`text-2xl sm:text-4xl text-center font-medium py-4 md:py-6 lg:text-6xl ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200`}>
          What Fuels Your Digital Journey?
        </h2>
        <div
          ref={sliderRef}
          style={{ cursor: `url(${slider}), auto` }}
          className={`flex overflow-x-scroll no-scrollbar space-x-4 w-full px-4 py-6 relative md:px-8  ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-100`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}>
          {cardData.map((card, index) => (
            <Card
              key={index}
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          ))}
        </div>
        <Link to="/services">
          <button
            className={`rounded-full  font-kanit bg-gradient-to-r from-[#1a5fe4] to-[#020639] hover:text-blue-700 hover:from-[#FFF] hover:to-[#FFF] text-white px-6 py-2 sm:px-8 sm:py-3 text-sm sm:text-lg mt-3 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in`}>
            More Services
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Cards;

import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import Faq from "./Faq";
import Testimonials from "./Testimonials";
import Discover from "./Discover";
import ad from "../assets/ad.svg";
import bd from "../assets/bd.svg";
import content from "../assets/content.svg";
import mr from "../assets/mr.svg";
import seo from "../assets/seo.svg";
import smd from "../assets/smd.svg";
import video from "../assets/video.svg";
import wd from "../assets/wd.svg";
// import { Link } from "react-router-dom";

const Card = ({ title, description, icon }) => {
  return (
    <div className="bg-gradient-to-br from-[#1a5fe4] to-[#020639] text-white rounded-3xl p-6 shadow-lg flex flex-col w-full h-auto md:h-80 shrink-0 gap-6 md:gap-10">
      {/* Content Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center h-auto md:h-20">
        <h2 className="text-2xl md:text-3xl lg:text-4xl w-full md:w-[75%] font-medium mb-4 md:mb-0 leading-snug md:leading-tight">
          {title}
        </h2>
        {/* Icon Section */}
        <img
          src={icon}
          alt={title}
          className="w-16 h-16 md:w-20 md:h-20 self-center md:self-end"
        />
      </div>
      <p className="text-base md:text-lg leading-relaxed font-light font-kanit">{description}</p>
    </div>
  );
};

const Services = () => {
   const [isVisible, setIsVisible] = useState(false);
   const sectionRef = useRef(null);

   useEffect(() => {
     const observer = new IntersectionObserver(
       ([entry]) => {
         if (entry.isIntersecting) {
           setIsVisible(true);
         }
       },
       { threshold: 0.5 } // Trigger when 50% of the component is visible
     );

     const currentRef = sectionRef.current;
     if (currentRef) {
       observer.observe(currentRef);
     }

     return () => {
       if (currentRef) {
         observer.unobserve(currentRef);
       }
     };
   }, []);
  
   const cardData = [
     {
       title: "Advertising Campaigns",
       description:
         "Ready to connect with your audience wherever they shop, scroll, stream, or read? This is achievable now with Cypera's advertising campaigns. Together let us show up in the spaces your audience loves most and drive traffic, boost sales, and build loyalty.",
       icon: ad,
     },
     {
       title: "Content Creation",
       description:
         "Looking to create content that delivers true results? With Cypera, create content that captivates, converts, and dominates. From blog posts, articles, and social media posts to videos, everything you need to achieve unparalleled visibility and impact.",
       icon: content,
     },
     {
       title: "Market Research",
       description:
         "Curious about what drives your audience? Enter into the current market trends and buyer behaviors with Cypera's expert team, craft smarter strategies for hitting the right audience, and watch your ROI soar.",
       icon: mr,
     },
     {
       title: "Brand Development",
       description:
         "Is your brand making the impact it deserves? With Cypera, go beyond competition and take the lead. Transform insights into action, overcome brand challenges, and amplify your market presence like never before.",
       icon: bd,
     },
     {
       title: "Video and Motion Graphics",
       description:
         "Want to turn heads and captivate hearts? Team up with Cypera's creative minds to produce stunning videos and motion graphics that bring your story to life, highlight your products, and keep your audience engaged.",
       icon: video,
     },
     {
       title: "Social Media Design",
       description:
         "Tired of being just another post in the feed? With Cypera’s eye-catching designs, from bold logos to captivating visuals, ensure your brand grabs attention, boosts visibility, and makes a memorable impression.",
       icon: smd,
     },
     {
       title: "SEO Services",
       description:
         "Ready to take your website to the top? Cypera is here to fine-tune every element from keywords, search engine alignment, and technical enhancements, making your website more visible, more powerful, and fully optimized for any device.",
       icon: seo,
     },
     {
       title: "Website Design",
       description:
         "What if your website could be your brand's best storyteller? With Cypera's responsive, intuitive UI/UX designs, ensure an engaging experience across devices while letting your brand’s story shine.",
       icon: wd,
     },
   ];
  
  return (
    <>
      <div ref={sectionRef} className="border-b border-gray-600 text-center">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <h1
            className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            Growth Solutions
          </h1>
          <div
            className={`text-lg font-kanit max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            <p>Partner with Us</p>
            <p>Bringing You, Your Brand to Real People, Real Leads. </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-6xl mx-auto my-8 px-4">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            icon={card.icon}
          />
        ))}
      </div>

      <Faq />

      <Testimonials />

      <Discover />
    </>
  );
};

export default Services;

import React, { useState, useEffect, useRef } from "react";
// import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import HeroBanner from "./HeroBanner/Banner";
import AnimatedVideo from "./AnimatedVideo";
import Clients from "./BrandCrousel";
import Cards from "./Cards";
import Features from "./Features";
import Stats from "./Stats";
import Testimonials from "./Testimonials";
import Faq from "./Faq";
import Blogs from "./Blogs";
import Discover from "./Discover";
// import Footer from "./Footer";
// import UpArrow from "./UpArrow";
import Dots from "../assets/dots.svg";
import Interactive from "./Interactive";
// import brand from "../assets/brand.svg"

const Homeindex = () => {
const [isVisible, setIsVisible] = useState(false);
const sectionRef = useRef(null);

useEffect(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      }
    },
    { threshold: 0.5 } // Trigger when 50% of the component is visible
  );

  const currentRef = sectionRef.current;
  if (currentRef) {
    observer.observe(currentRef);
  }

  return () => {
    if (currentRef) {
      observer.unobserve(currentRef);
    }
  };
}, []);
  
  return (
    <div className="">
      <div className="relative">
        <img
          src={Dots}
          alt="dots"
          className="absolute top-0 left-1/2 transform -translate-x-1/2 opacity-55 w-[70%] h-auto"
        />
        <HeroBanner />
      </div>

      {/* <div className="flex items-center justify-center bg-white"> */}
      <Clients />
      {/* </div> */}

      <div className="flex items-center justify-center flex-col space-y-20">
        <AnimatedVideo />
      </div>

      <div
        ref={sectionRef}
        className="text-center py-20 max-w-7xl mx-auto text-white px-4">
        <h2
          className={`text-4xl md:text-6xl font-medium mb-4 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200`}>
          Where Strategy Becomes Impact
        </h2>
        <p
          className={`text-lg max-w-7xl text-gray-400 md:mx-0 mx-6 font-kanit font-extralight${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-100`}>
          At Cypera, we don’t create projects, we build brands. Infusing
          creativity, innovation, and data, we deliver impactful results. Ready
          to disrupt the status quo and make a real impact? Let’s lead the way
          forward!
        </p>
      </div>

      <Cards />

      <Interactive />

      <Features />

      <Stats />

      <Testimonials />

      <Faq />

      <Blogs />

      <Discover />
    </div>
  );
};

export default Homeindex;

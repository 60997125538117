import React from "react";
// import blogImage1 from "../assets/blog1.jpg";
// import blogImage2 from "../assets/blog2.jpg";
// import blogImage3 from "../assets/blog3.jpg";

const BlogCard = ({ image, category, title }) => {
  return (
    <div className="bg-[#1a1a1a] rounded-xl overflow-hidden shadow-lg">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <span className="inline-block bg-blue-600 text-white text-xs px-2 py-1 rounded-full mb-2">
          {category}
        </span>
        <h3 className="text-white text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

const Blogs = () => {
  const blogData = [
    {
      image: "https://via.placeholder.com/500X500",
      category: "Articles",
      title: "What is CAC and how do I calculate it?",
    },
    {
      image: "https://via.placeholder.com/500X500",
      category: "Case Studies",
      title:
        "How you can use recurring revenue financing for faster growth without dilution",
    },
    {
      image: "https://via.placeholder.com/500X500",
      category: "Funding",
      title: "Announcing Slang.ai's $20M in funding",
    },
  ];

  return (
    <section className="py-12 text-center">
      <h2 className="text-4xl text-white font-bold mb-6">From our Blog</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto px-4 ">
        {blogData.map((blog, index) => (
          <BlogCard
            key={index}
            image={blog.image}
            category={blog.category}
            title={blog.title}
          />
        ))}
      </div>
      <button className="p-4 font-kanit my-8 px-8 bg-gradient-to-r from-[#FFF] to-[#FFF] text-[#1C65f0] hover:text-white hover:from-[#020639] hover:to-[#1a5fe4] text-base rounded-full">
        More Articles
      </button>
    </section>
  );
};

export default Blogs;

import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";
import Faq from "./Faq";
import Map from "./Map";
import Discover from "./Discover";
// import GetHelp from "./GetHelp";
import axios from "axios";

const Contact = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [, setSelectedServices] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    services: [],
    budget: "",
    details: "",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  // form submit

  const handleChange = (event) => {
    const { value, checked } = event.target;

    setSelectedServices((prevServices) => {
      const updatedServices = checked
        ? [...prevServices, value] // Add service if checked
        : prevServices.filter((service) => service !== value); // Remove service if unchecked
  
      // Sync the updated services with formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        services: updatedServices,
      }));

    return updatedServices;
  });
}

const handleSubmit = (e) => {
  e.preventDefault();
  axios
    .post("https://cyperadigital.com/Backend/contactus.php", formData)
    .then(response => {
      alert(response.data.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        companyName: "",
        services: [],
        budget: "",
        details: "",
      }); // Reset form
    })
    .catch(error => console.error("Error inserting data:", error));
};

  return (
    <>
      <div ref={sectionRef} className="border-b border-gray-600 text-center">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <h1
            className={`text-5xl md:text-7xl max-w-6xl font-semibold mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            Curious or inspired?
          </h1>
          <p
            className={`text-lg font-kanit max-w-3xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            Get everything you need at Cypera to hit your entrepreneurial goals!
          </p>
        </div>
      </div>

      <div class="min-h-screen bg-gradient-to-r from-black via-gray-900 to-gray-800 text-white py-10">
        <div class="max-w-5xl mx-auto px-4">
          <form onSubmit={handleSubmit} class="space-y-6 bg-transparent p-8">
            {/* Full Name */}
            <div>
              <label class="block text-sm font-medium mb-2" for="full-name">
                Full Name *
              </label>
              <input
                id="full-name"
                type="text"
                placeholder="Your Full Name"
                value={formData.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
                class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
              />
            </div>

            {/* Email and Phone Section */}
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
              {/* Email */}
              <div>
                <label class="block text-sm font-medium mb-2" for="email">
                  Email *
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={e =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
                />
              </div>
              {/* Phone Number */}
              <div>
                <label class="block text-sm font-medium mb-2" for="phone">
                  Phone Number *
                </label>
                <input
                  id="phone"
                  type="text"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={e =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
                />
              </div>
            </div>

            {/* Company Name */}
            <div>
              <label class="block text-sm font-medium mb-2 pt-6" for="company">
                Company Name
              </label>
              <input
                id="company"
                type="text"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={e =>
                  setFormData({ ...formData, companyName: e.target.value })
                }
                class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"
              />
            </div>

            {/* Services Interested In */}
            <div>
              <label className="block text-sm font-medium mb-2 pt-6">
                Services Interested In
              </label>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {[
                  "Web Design",
                  "Branding",
                  "Mobile App Design",
                  "Social Media Management",
                  "SEO Optimization",
                  "Graphic Design",
                  "Digital Marketing",
                  "Video Production",
                  "UX/UI Design",
                  "E-commerce Solutions",
                  "Content Creation",
                  "Consulting Services",
                ].map(service => (
                  <label key={service} className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 mr-2"
                      value={service}
                      onChange={handleChange}
                    />
                    {service}
                  </label>
                ))}
              </div>
              {/* <div className="mt-4">
                <strong>Selected Services:</strong>{" "}
                {selectedServices.join(", ")}
              </div> */}
            </div>

            {/* Estimated Budget */}
            <div>
              <label class="block text-sm font-medium mb-2 pt-6" for="budget">
                Estimated Budget *
              </label>
              <select
                id="budget"
                value={formData.budget}
                onChange={e =>
                  setFormData({ ...formData, budget: e.target.value })
                }
                class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6">
                <option>Select...</option>
                <option>Under $1,000</option>
                <option>$1,000 - $5,000</option>
                <option>$5,000 - $10,000</option>
                <option>$10,000 - $20,000</option>
                <option>$20,000+</option>
              </select>
            </div>

            {/* Project Details */}
            <div>
              <label class="block text-sm font-medium mb-2 pt-6" for="details">
                Project Details *
              </label>
              <textarea
                id="details"
                rows="5"
                value={formData.details}
                onChange={e =>
                  setFormData({ ...formData, details: e.target.value })
                }
                placeholder="Provide a brief description of your project, goals, and any specific requirements..."
                class="w-full bg-black/80 border-b border-white focus:ring-2 focus:ring-blue-500 px-4 py-6"></textarea>
            </div>

            {/* Submit Button */}
            <div class="text-center">
              <div class="group relative">
                <button
                  type="submit"
                  class="bg-white font-kanit text-black text-lg px-8 hover:px-10 py-4 rounded-full hover:bg-gray-100 shadow-lg">
                  Send Request
                  <span class="absolute px-2 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all duration-300">
                    &#8594;
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* <GetHelp /> */}

      <Map />

      <Faq />

      <Discover />
    </>
  );
};

export default Contact;

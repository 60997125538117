import React, { useState, useEffect, useRef } from "react";

const FaqsCard = ({ faqsList, idx }) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(state ? "0px" : `${answerElH + 20}px`);
  };

  return (
    <div
      className={`group border border-gray-300 hover:border-[#626162] hover:bg-[#f8f8f1] transition-colors ease-out duration-300 mt-5 overflow-hidden rounded-xl ${
        state ? "bg-[#f8f8f1]" : "bg-[#262626]"
      }`}
      key={idx}
      onClick={handleOpenAnswer}>
      <h4
        className={`cursor-pointer p-5 flex items-center justify-between text-lg sm:text-xl ${
          state ? "text-gray-800" : "text-gray-100"
        } hover:text-gray-800 font-medium`}>
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}>
        <div>
          <p className="text-gray-800 px-5 text-sm sm:text-base">
            {faqsList.a}
          </p>
        </div>
      </div>
    </div>
  );
};

const Faq = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const faqsList = [
    {
      q: "What is Digital Marketing for Brands?",
      a: "Digital marketing involves using strategies like market research, SEO, PPC, social media, and brand development to boost visibility, engage audiences, and drive measurable results.",
    },
    {
      q: "What are the key factors for success in digital marketing?",
      a: "There are several key factors for success in digital marketing. Some of the most common variables include the right strategy, audience targeting, consistent branding, high-quality content, and leveraging platforms like Google, Instagram, or LinkedIn.",
    },
    {
      q: "Should I hire a digital marketing agency or do it myself?",
      a: "It depends on personal goals and requirements. While DIY is possible but requires significant time, resources, and industry knowledge. Conversely, partnering with digital platforms can bring expertise, tools, and strategies tailored to your goals, saving time & maximizing results. ",
    },
    {
      q: "How can I determine the right digital marketing services for my business?",
      a: "To determine the right services, we advise you to schedule a free consultation call with our expert team! Here we will be assessing your business goals and recommend the most effective digital marketing strategies specifically tailored to your needs.",
    },
    {
      q: "What are the costs of your digital marketing services?",
      a: "We offer flexible and customized prices based on the scope and complexity of your project. For more details, you can Contact Us for a personalized quote that will directly align with your goals.",
    },
    {
      q: "How long does it take to see results from digital marketing?",
      a: "The results can vary and depend on the service you choose for your brand. For instance, if you book our PPC services, you can see measurable results within days, while strategies like SEO can take several months to show sustainable growth.",
    },
  ];

  return (
    <section
      ref={sectionRef}
      id="faq"
      className="leading-relaxed pb-14 my-10 px-5 sm:px-10 xl:px-0 max-w-6xl mx-auto">
      <div className="space-y-3 text-center">
        <h1
          className={`text-3xl sm:text-4xl md:text-5xl text-white font-medium ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200`}>
          We’ve got you covered
        </h1>
      </div>
      <div
        className={`mt-8 text-left max-w-screen-lg mx-auto ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200 delay-150`}>
        {faqsList.map((item, idx) => (
          <FaqsCard key={idx} idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};

export default Faq;

import React, { useState, useEffect, useRef } from "react";
import GradientCircleAnimation from "./HeroBanner/GradientCircles";

const CaseStudies = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  return (
    <>
      <div
        ref={sectionRef}
        className="border-b border-gray-600 text-center px-4">
        <GradientCircleAnimation
          numberOfCircles={
            window.innerWidth < 640 ? 2 : window.innerWidth < 1024 ? 3 : 4
          }
          size={
            window.innerWidth < 640 ? 200 : window.innerWidth < 1024 ? 300 : 400
          }
          className="absolute top-0 overflow-hidden"
        />
        <div className="flex flex-col gap-4 my-12 mt-36">
          <div
            className={`max-w-6xl mx-auto bg-white/70 text-transparent bg-clip-text mb-4 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-10"
            } transition-all ease-in duration-200`}>
            <h1 className="font-bold text-5xl md:text-7xl">Skilcamp</h1>
            <h2 className="text-4xl md:text-6xl  font-medium">
              Transforming E-Learning With One Click at a Time
            </h2>
          </div>
          <p
            className={`text-lg font-kanit max-w-5xl mx-auto bg-white/70 text-transparent bg-clip-text mb-8 ${
              isVisible
                ? "opacity-100 scale-100 translate-y-0"
                : "opacity-0 scale-95 translate-y-4"
            } transition-all ease-in duration-200 delay-100`}>
            We had the privilege of working with Skilcampto to redefine the
            e-learning experience, bringing a bold vision to life that inspired
            users, elevated learning, and set new industry standards.
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center h-fit">
        <div
          className="relative w-[70%] md:w-[60%] lg:w-[40%] aspect-video shadow-2xl shadow-blue-400 ring-8 rounded-lg"
          style={{
            transform:
              "perspective(542px) rotate(22deg) rotateX(8.5deg) rotateY(-28deg) skewX(0.6deg) skewY(-16.3deg)",
            transformOrigin: "100% 50% 0px",
            translate: "-3em",
          }}>
          <iframe
            src="https://skilcamp.com/"
            title="Skillcamp"
            className="w-full h-full rounded-lg shadow-lg border border-gray-300"
            loading="lazy"
            fetchpriority="auto"
            referrerpolicy="no-referrer"
            sandbox="allow-same-origin allow-scripts allow-downloads allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"></iframe>
        </div>
      </div>

      <div className=" text-white py-10 px-5 md:px-20 lg:px-40">
        <h2 className="text-3xl font-bold mb-6">Skilcamp’s Vision</h2>

        {/* Section 1 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-3">
            Transforming Business Functions with SaaS
          </h3>
          <p className="text-gray-300 font-light font-kanit">
            In today’s digital age, businesses face the constant challenge of
            enhancing efficiency, streamlining processes, and driving growth.
            SaaS has emerged as a game-changing solution, enabling organizations
            to unlock their true potential by harnessing the power of
            cloud-based applications. In this article, we will delve into the
            world of SaaS, exploring its benefits, implementation strategies,
            and the impact it can have on businesses across industries.
          </p>
        </div>

        {/* Section 2 */}
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-3">Skilcamp’s Vision</h3>
          <p className="text-gray-300 font-light font-kanit">
            To create a dynamic, scalable, and globally accessible e-learning
            platform that offers an engaging user experience, modernizing the
            interface, and ensuring seamless adaptability across devices.
          </p>
        </div>

        {/* Benefits List */}
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-3">The Challenge</h4>
          <ul className="list-disc list-inside space-y-2 text-gray-300 font-kanit font-light">
            <li>Low user engagement</li>
            <li>Limited flexibility and adaptability</li>
            <li>Scalability issues</li>
            <li>Accessibility barriers across devices</li>
            <li>Outdated user experience</li>
          </ul>
        </div>

        {/* Section 3 */}
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-3">
            Our Strategy for Success
          </h4>

          <ol className="list-decimal list-inside space-y-2 font-kanit font-light text-gray-300">
            <li>
              <b>Learner-First Design:</b> We created an easy-to-use, visually
              attractive interface that made learning fun and simple.
            </li>
            <li>
              <b>Engaging Experiences:</b> With gamification, videos, and
              personalized paths, we turned users into active learners.
            </li>
            <li>
              <b>Future-Ready Scalability:</b> A strong cloud-based system
              allowed the platform to grow and reach learners everywhere
            </li>
          </ol>
        </div>

        {/* Section 4 */}
        <div className="mb-8">
          <h4 className="text-lg font-semibold mb-3">The Impact</h4>
          <div className="text-gray-300 flex md:flex-row flex-col font-light font-kanit justify-between">
            <p>
              <b>100,000+ Learners</b>
            </p>
            <p>
              <b>75% Retention Increase</b>
            </p>
            <p>
              <b>Seamless Scalability</b>
            </p>
          </div>
        </div>

        {/* Conclusion */}
        <div>
          <h4 className="text-lg font-semibold mb-3">
            What Set This Project Apart?
          </h4>
          <p className="text-gray-300 font-light font-kanit">
            Skilcamp wasn’t just another project. It was a movement. By blending
            creativity, innovation, and data-driven insights, our expert built a
            platform that didn’t just teach but inspired. This wasn’t about
            following trends. It was about setting them.
          </p>
        </div>
      </div>
    </>
  );
};

export default CaseStudies;

import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/Logo_White_Cypera.svg";
// import fb from "../assets/fb.svg";
// import insta from "../assets/insta.svg";
// import linkedin from "../assets/linkedin.svg";
// import twitter from "../assets/twitter.svg";
import { Link } from "react-router-dom";

function Footer() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    const currentRef = sectionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className=" text-white pt-4 max-w-[95%] mx-auto">
      <div
        className={`grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-2 md:gap-8  mx-auto px-8 py-6 justify-between items-center md:border border-gray-500 rounded-full ${
          isVisible
            ? "opacity-100 scale-100 translate-y-0"
            : "opacity-0 scale-95 translate-y-10"
        } transition-all ease-in duration-200`}>
        <a
          href="mailto:help@cyperadigital.com"
          className="flex items-center justify-center gap-2 hover:text-orange-500 hover:underline transition-all ease-in duration-100 border border-gray-500 rounded-full text-center py-2">
          <span className="">
            <svg
              viewBox="0 0 256 256"
              color="rgb(255, 255, 255)"
              width="20"
              height="20">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
          </span>
          help@cyperadigital.com
        </a>

        <a
          href="tel:+91 8904366659"
          className="flex items-center justify-center gap-2 hover:text-orange-500 hover:underline transition-all ease-in duration-100 border border-gray-500 rounded-full text-center py-2">
          <span>
            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="20"
              height="20">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M144.27,45.93a8,8,0,0,1,9.8-5.66,86.22,86.22,0,0,1,61.66,61.66,8,8,0,0,1-5.66,9.8A8.23,8.23,0,0,1,208,112a8,8,0,0,1-7.73-5.93,70.35,70.35,0,0,0-50.33-50.34A8,8,0,0,1,144.27,45.93Zm-2.33,41.8c13.79,3.68,22.65,12.55,26.33,26.34A8,8,0,0,0,176,120a8.23,8.23,0,0,0,2.07-.27,8,8,0,0,0,5.66-9.8c-5.12-19.16-18.5-32.54-37.66-37.66a8,8,0,1,0-4.13,15.46Zm72.43,78.73-47.11-21.11-.13-.06a16,16,0,0,0-15.17,1.4,8.12,8.12,0,0,0-.75.56L126.87,168c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16,16,0,0,0,1.32-15.06l0-.12L89.54,41.64a16,16,0,0,0-16.62-9.52A56.26,56.26,0,0,0,24,88c0,79.4,64.6,144,144,144a56.26,56.26,0,0,0,55.88-48.92A16,16,0,0,0,214.37,166.46Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
          </span>
          +91 8904366659
        </a>

        <a
          href="https://maps.app.goo.gl/uMenc2nG6qa2n71r9"
          target="blank"
          className=" flex hover:text-orange-400 hover:underline items-center justify-center gap-2 border border-gray-500 rounded-full text-center py-2">
          <span>
            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="20"
              height="20">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M128,16a88.1,88.1,0,0,0-88,88c0,75.3,80,132.17,83.41,134.55a8,8,0,0,0,9.18,0C136,236.17,216,179.3,216,104A88.1,88.1,0,0,0,128,16Zm0,56a32,32,0,1,1-32,32A32,32,0,0,1,128,72Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
          </span>
          India
        </a>
        <a
          href="https://maps.app.goo.gl/1uXpVCSv5QDWVLeS9"
          target="blank"
          className="flex items-center hover:text-orange-400 hover:underline justify-center gap-2 border border-gray-500 rounded-full text-center py-2">
          <span>
            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="20"
              height="20">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M128,16a88.1,88.1,0,0,0-88,88c0,75.3,80,132.17,83.41,134.55a8,8,0,0,0,9.18,0C136,236.17,216,179.3,216,104A88.1,88.1,0,0,0,128,16Zm0,56a32,32,0,1,1-32,32A32,32,0,0,1,128,72Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
          </span>
          Dubai
        </a>
      </div>
      <div className="h-fit">
        {/* <div
          className={`flex flex-col my-12 max-w-screen mx-auto px-4 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-100`}></div> */}
        <div
          className={`px-2 my-10 ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-200`}>
          <img src={logo} alt="cypera" className=" md:h-fit md:w-full p-2" />
        </div>
        <div
          className={`md:flex justify-between items-center border-t border-x rounded-t-2xl border-gray-200 text-white p-2 px-4 pt-8 pb-4 mt-1  ${
            isVisible
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 translate-y-10"
          } transition-all ease-in duration-200 delay-200`}>
          <p>Cyperadigital•Copyright© 2024</p>
          <div className="flex gap-4">
            <Link
              to="/case-studies"
              className="hover:text-orange-500 hover:underline">
              Success Stories
            </Link>
            <Link
              to="/services"
              className="hover:text-orange-500 hover:underline">
              Services
            </Link>
            <Link
              to="/career"
              className="hover:text-orange-500 hover:underline">
              Career
            </Link>
            <Link
              to="/contact"
              className="hover:text-orange-500 hover:underline">
              Talk to Us
            </Link>
            <Link to="/blog" className="hover:text-orange-500 hover:underline">
              Insights Hub
            </Link>
          </div>
          <div className="flex gap-4 items-center md:justify-center ">
            {/* <img src={fb} alt="fb" className=" h-6 w-6 m-2" /> */}
            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="25"
              height="25">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M232,128a104.16,104.16,0,0,1-91.55,103.26,4,4,0,0,1-4.45-4V152h24a8,8,0,0,0,8-8.53,8.17,8.17,0,0,0-8.25-7.47H136V112a16,16,0,0,1,16-16h16a8,8,0,0,0,8-8.53A8.17,8.17,0,0,0,167.73,80H152a32,32,0,0,0-32,32v24H96a8,8,0,0,0-8,8.53A8.17,8.17,0,0,0,96.27,152H120v75.28a4,4,0,0,1-4.44,4A104.15,104.15,0,0,1,24.07,124.09c2-54,45.74-97.9,99.78-100A104.12,104.12,0,0,1,232,128Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
            {/* <img src={twitter} alt="twitter" className="  h-6 w-6 m-2" /> */}
            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="25"
              height="25">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M245.66,77.66l-29.9,29.9C209.72,177.58,150.67,232,80,232c-14.52,0-26.49-2.3-35.58-6.84-7.33-3.67-10.33-7.6-11.08-8.72a8,8,0,0,1,3.85-11.93c.26-.1,24.24-9.31,39.47-26.84a110.93,110.93,0,0,1-21.88-24.2c-12.4-18.41-26.28-50.39-22-98.18a8,8,0,0,1,13.65-4.92c.35.35,33.28,33.1,73.54,43.72V88a47.87,47.87,0,0,1,14.36-34.3A46.87,46.87,0,0,1,168.1,40a48.66,48.66,0,0,1,41.47,24H240a8,8,0,0,1,5.66,13.66Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
            {/* <img src={insta} alt="instagram" className="  h-6 w-6 m-2" /> */}

            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="25"
              height="25">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M216,24H40A16,16,0,0,0,24,40V216a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V40A16,16,0,0,0,216,24ZM96,176a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0ZM88,96a12,12,0,1,1,12-12A12,12,0,0,1,88,96Zm96,80a8,8,0,0,1-16,0V140a20,20,0,0,0-40,0v36a8,8,0,0,1-16,0V112a8,8,0,0,1,15.79-1.78A36,36,0,0,1,184,140Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
            <svg
              viewBox="0 0 256 256"
              focusable="false"
              color="rgb(255, 255, 255)"
              width="25"
              height="25">
              <g color="rgb(255, 255, 255)" weight="fill" fill="#FFFFFF">
                <path
                  d="M176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24ZM128,176a48,48,0,1,1,48-48A48.05,48.05,0,0,1,128,176Zm60-96a12,12,0,1,1,12-12A12,12,0,0,1,188,80Zm-28,48a32,32,0,1,1-32-32A32,32,0,0,1,160,128Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
            {/* <img src={linkedin} alt="linkedin" className="  h-6 w-6 m-2" /> */}
          </div>
          {/* <div className="flex items-center gap-2">
            <a href="/policy" alt="policy" className=" hover:text-[#ffd50d]">
              policy
            </a>{" "}
            | {"  "}
            <a
              href="/privacy"
              alt="privacy policy"
              className=" hover:text-[#ffd50d]">
              {"  "} privacy policy
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
